.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.secondary {
  ul {
    border-right: none;
  }
  span {
    font-size: 12px;
    color: #aaa;
  }
  li {
    background-color: #001529;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    &::after {
      display: none !important;
    }
  }

  padding-top: 45px;
}
